exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-footer-previews-js": () => import("./../../../src/pages/footer-previews.js" /* webpackChunkName: "component---src-pages-footer-previews-js" */),
  "component---src-pages-header-previews-js": () => import("./../../../src/pages/header-previews.js" /* webpackChunkName: "component---src-pages-header-previews-js" */),
  "component---src-pages-popup-previews-js": () => import("./../../../src/pages/popup-previews.js" /* webpackChunkName: "component---src-pages-popup-previews-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

